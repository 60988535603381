import React from 'react';

import Meta from '../components/meta';

import { Link } from 'gatsby';

import ExtLink from '../components/extLink';

import Contents from '../components/contents';

const Policy = () =>{



    return(
        <>

            <Meta
                title="個人情報の取り扱いについて"
                description="アクロスソリューションズの個人情報の取り扱いについて"
            />

            <Contents>

                <article className="p-recruit-jobs is-policy">

                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                個人情報の取り扱いについて
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    1.個人情報の利用目的
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社では、個人情報保護法で定められている個人情報、個人関連情報等について以下の利用目的において収集し利用します。
                                        </p>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                                (1)お取引先様、お客様等の個人情報
                                            </p>

                                            <ul className="p-recruit-data-box-lists">
                                                <li>
                                                お取引先様、お客様等へのご請求・お支払等の業務処理、契約関係処理および業務上のご連絡
                                                </li>
                                                <li>
                                                お取引先様、お客様等からのお問い合わせ・ご依頼への対応
                                                </li>
                                                <li>
                                                弊社サービスのご案内
                                                </li>
                                                <li>
                                                弊社およびグループ各社が主催、協賛するセミナー、商品説明会、展示会へのご案内
                                                </li>
                                                <li>
                                                満足度調査等のアンケートのご依頼
                                                </li>
                                                <li>
                                                年賀状、事務所移転、異動等のご案内
                                                </li>
                                                <li>
                                                その他、お取引先様、お客様等に事前にお知らせし、ご同意いただいた目的での利用
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                                (2)受託業務においてお預かりする個人情報
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            お取引先様からお預かりした個人情報をデータ処理等の受託業務遂行の目的の範囲内で取り扱います。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                                (3)協力企業様社員の個人情報
                                            </p>

                                            <ul className="p-recruit-data-box-lists">
                                                <li>
                                                協力企業様へのご請求・お支払等の業務処理、契約関係処理および業務上のご連絡
                                                </li>
                                                <li>
                                                協力企業様からのお問い合わせ・ご依頼への対応
                                                </li>
                                                <li>
                                                弊社サービスのご案内
                                                </li>
                                                <li>
                                                弊社およびグループ各社が主催、協賛するセミナー、商品説明会、展示会へのご案内
                                                </li>
                                                <li>
                                                協力企業様満足度調査等のアンケートのご依頼
                                                </li>
                                                <li>
                                                年賀状、事務所移転、異動等のご案内
                                                </li>
                                                <li>
                                                その他、協力企業様に事前にお知らせし、ご同意いただいた目的での利用
                                                </li>
                                            </ul>
                                        </div>



                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                                (4)役員・従業員の個人情報
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            弊社の役員および従業員（従業員、嘱託従業員、パート従業員、出向受入社員、派遣社員。以下の目的に必要な範囲で、これらの家族の個人情報も含みます。）、もしくは、弊社の採用応募者等および退職者の個人情報を以下の目的で取り扱います。
                                            </p>

                                            <ul className="p-recruit-data-box-lists">
                                                <li>
                                                人事・労務、報酬の計算・決定・支払
                                                </li>
                                                <li>
                                                研修、解職・退職、その他の雇用管理
                                                </li>
                                                <li>
                                                業績管理、適正な業務運営の確保
                                                </li>
                                                <li>
                                                会社から従業員への連絡等
                                                </li>
                                                <li>
                                                採用選考に関する業務、入社手続き
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    2.個人情報の委託について
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社では、業務を円滑に遂行するため、業務の一部を委託先に委託し、当該委託先に対し必要な範囲で個人情報の取り扱いを委託する場合がありますが、この場合は、当社が定めた基準を満たす者を委託先として選定するとともに、個人情報の取り扱いに関する契約の締結や適切な監督を行います。また1.（2）に該当する個人情報についてはお取引先様のご了解をいただいた上で取り扱いを委託します。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    3.個人情報の共同利用について
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、１.（1）、(3)、（4）の個人情報について共同利用する場合があります。
                                        </p>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                                (1)個人情報の項目
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            氏名、会社名、住所、電話番号、メールアドレス、従業員の給与、社会保険、勤務状況等に関する情報その他の人事管理及び福利厚生に関する情報、その他利用目的を達成するために必要な項目
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                                (2)共同利用者の範囲
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            <Link to="https://www.sts-inc.co.jp/company/company_group.html" className="c-text--link" target="_blank">弊社のグループ会社<i className="fa fa-external-link ml"></i></Link>
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                                (3)利用目的
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            1.（1）、(3)、（4）の利用目的の範囲内で共同利用します。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                                (4)個人情報の管理について責任を有する会社の名称・住所・代表者等
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            名称：株式会社アクロスソリューションズ<br />
                                            住所：石川県金沢市北安江3丁目6-6 メッセヤスダ1F/2F<br />
                                            代表者：代表取締役社長　能登 満<br />
                                            <br />
                                            共同利用についてのお問い合わせは、以下の通りです。<br />
                                            <br />
                                            <strong>【連絡先】</strong><br />
                                            石川県金沢市北安江3丁目6-6 メッセヤスダ1F/2F<br />
                                            株式会社アクロスソリューションズ　管理部　個人情報保護相談窓口担当<br />
                                            電話番号：076-255-2012<br />
                                            受付時間：平日9時～18時（土・日・祝日除く）
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    4.個人情報の第三者提供について
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        以下の場合を除き、本人の承諾を得ずに個人情報を第三者提供することはありません。
                                        </p>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--text">
                                            ａ）法令の規定による場合
                                            </p>
                                            <p className="p-recruit-data-box-list--text">
                                            ｂ）人の生命、身体又は財産を保護するために特に必要な場合であって、本人の同意を得ることが困難であるとき
                                            </p>
                                            <p className="p-recruit-data-box-list--text">
                                            ｃ）上記3.の共同利用者の範囲に掲げる者に対して共同利用のために提供する場合
                                            </p>
                                            <p className="p-recruit-data-box-list--text">
                                            ｄ）その他、個人情報保護法に定める場合
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    5.安全管理措置について
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、個人情報について、漏洩、滅失又は毀損の防止等、その管理のために必要かつ適切な安全管理措置を講じます。また、個人情報を取り扱う従業者や委託先（再委託先等を含みます。）に対して、必要かつ適切な監督を行います。個人情報の安全管理措置に関しては、社内規程において具体的に定めており、その主な内容は以下の通りです。
                                        </p>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            （個人情報保護指針の策定）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            個人情報の適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」、「個人情報の開示・訂正・削除等請求方法」等について指針を策定しています。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            （個人情報の取扱いに係る規律の整備）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について社内で決めております。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            （組織的安全管理措置）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            個人情報の取扱いに関する拠点責任者を設置するとともに、個人情報を取り扱う従業者及び当該従業者が取り扱う個人情報の範囲を明確化し、個人情報保護法や個人情報取扱に関する社内規程に違反している事実又は兆候を把握した場合の情報セキュリティ管理責任者への報告連絡体制を整備しています。個人情報の取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の第三者による監査を実施しています。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            （人的安全管理措置）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            個人情報の取扱いに関する留意事項について、従業者に定期的な研修を実施しております。個人情報についての秘密保持に関する事項を社内規程に記載しています。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            （物理的安全管理措置）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            個人情報を取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人情報の閲覧を防止する措置を実施しています。個人情報を取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人情報が判明しないよう措置を実施しています。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            （技術的安全管理措置）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定しています。個人情報を取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            （外的環境の把握）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            弊社が収集した個人情報を外国において保管する場合、保管している外国における個人情報の保護に関する制度等を把握し、個人データの安全管理のために必要かつ適切な措置を講じている委託先を選定した上で委託いたします。
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    6.要配慮個人情報の取扱い
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        要配慮個人情報とは、個人情報のうち、本人の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱いに特に配慮を要するものとして政令で定める記述等が含まれるものをいいます。人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実等のほか、身体障害、知的障害、精神障害等の障害があること、健康診断その他の検査の結果、保健指導、診療・調剤情報等が該当します。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、個人情報保護法第20条第2項各号に掲げる場合を除くほか、あらかじめご本人の同意を得ないで、要配慮個人情報を取得いたしません。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    7.仮名加工情報
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、仮名加工情報（個人情報保護法第2条第5項に定めるものを意味します。以下同じ。）を作成するときは、個人情報保護法41条第1項及び個人情報保護委員会規則等の法令が定める基準に従い、個人情報を加工するものとします。
                                        </p>
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、仮名加工情報を作成したときまたは仮名加工情報及び当該仮名加工情報に係る削除情報等を取得したときは、削除情報等の漏えいを防止するために必要なものとして個人情報保護委員会規則で定める基準に従い、削除情報等の安全管理のための措置を講じます。弊社は、法令に基づく場合のほか、仮名加工情報（個人情報に該当するものを除きます。）を第三者に提供いたしません。弊社は、仮名加工情報を取り扱うにあたっては、仮名加工情報の作成に用いられた個人情報に係る本人を識別するために、仮名加工情報を他の情報と照合しません。弊社は、仮名加工情報である個人情報及び削除情報等を利用する必要がなくなったときは、当該個人情報及び削除情報等を遅滞なく消去するよう努めます。弊社は、仮名加工情報の安全管理のために必要かつ適切な措置、仮名加工情報の作成その他の取り扱いに関する苦情の処理その他の仮名加工情報の適正な取り扱いを確保するために必要な措置を自ら講じ、かつ、当該措置の内容を公表するよう努めるものとします。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    8.匿名加工情報
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、匿名加工情報（個人情報保護法第2条第6項に定めるものを意味します。以下同じ。）を作成するときは、個人情報保護法43条第1項及び個人情報保護委員会規則等の法令が定める基準に従い、個人情報を加工するものとします。
                                        </p>
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、匿名加工情報を作成したときは、匿名加工情報の作成に用いた個人情報から削除した記述等及び個人識別符号並びに前項の規定により行った加工の方法に関する情報（その情報を用いて当該個人情報を復元することができるものに限ります。）の漏えいを防止するため、安全管理のための措置を講じます。弊社は、匿名加工情報を作成したときは、遅滞なく、インターネットの利用その他の適切な方法により、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。
                                        </p>
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、匿名加工情報（弊社が作成したもの及び第三者から提供を受けたものを含みます。以下別段の定めがない限り同様とします。）を第三者に提供するときは、あらかじめ、第三者に提供される匿名加工情報に含まれる個人に関する情報の項目及びその提供の方法について公表するとともに、当該第三者に対して、当該提供に係る情報が匿名加工情報である旨を明示します。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、匿名加工情報を取り扱うにあたっては、匿名加工情報の作成に用いられた個人情報に係る本人を識別するために、 
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        （1）匿名加工情報を他の情報と照合すること、及び
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        （2）当該個人情報から削除された記述等若しくは個人識別符号又は個人情報保護法第43条第1項の規定により行われた加工の方法に関する情報を取得すること（（2）は第三者から提供を受けた当該匿名加工情報についてのみ）を行わないものとします。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社は、匿名加工情報の安全管理のために必要かつ適切な措置、匿名加工情報の作成その他の取り扱いに関する苦情の処理その他の匿名加工情報の適正な取り扱いを確保するために必要な措置を自ら講じ、かつ、当該措置の内容を公表するよう努めるものとします。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    9.個人情報等に関する開示・訂正・利用停止・削除等
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        弊社が保有する個人情報等について、法の定めに従い開示等の請求や問い合わせのご相談に応じます。なお、本手続きにあたり、ご本人様であることを確認させていただくことがございます。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        〇弊社サービスを利用されているお客様の開示等請求やお問い合わせについては、お取引のある担当部門にご相談ください。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        〇その他の個人情報についての開示等請求やお問い合わせについては、下記問い合わせ先にご連絡下さい。詳細は下記【<Link to="/request" className="c-text--link" title="個人情報の開示・訂正・削除等の手続き">個人情報の開示・訂正・削除等の手続き</Link>】を御確認下さい。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        【<Link to="/request" className="c-text--link" title="個人情報の開示・訂正・削除等の手続き">個人情報の開示・訂正・削除等の手続き</Link>】
                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>



                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                外部送信ポリシー
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社では、本サイト利用者に関する情報を、利用者の端末を通して、当社または当社が提携する事業者に送信しています。なお、送信した情報は、送信先事業者の規定に基づいて管理・利用されています。情報の送信を停止したい場合は、各社のオプトアウトページや、ブラウザやスマートフォンなどの設定により停止することが可能です。具体的には、以下の目的でクッキーやその他のトラッキング技術を使用しています。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        1.クッキー（Cookie）とは<br />
                                        クッキーはWebサイトからご利用者の端末に記録目的で送信される小さなテキストファイルです。クッキーはご利用者の端末、通常Webブラウザを識別しますが、ご利用者を個人として識別することはありません。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        2.利用目的<br />
                                        クッキーはご利用者が快適にWebサイトを利用いただくために必要であり、プレファレンス（使用している言語など）やWebサイトにおける行動を記録します。クッキーは、機能を向上させ、パフォーマンスを改善するために分析データを収集し、また、インターネットにおいてご利用者をトラッキングすることにより当サイトもしくはアドテクパートナーからパーソナライズされた広告を表示することを可能にします。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        3.クッキーの無効化について<br />
                                        ご利用者は個々のWebサイトで表示されるクッキーバナーやクッキー設定ボタンから、当サイトもしくは第三者（分析パートナー）に対する、Webサイトの機能に厳格に必要でないクッキーの許可 / 拒否を選択することが可能です。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">

                                    <p className="p-recruit-data-box--title is-sub u-margin-bottom__1">
                                    クッキーリスト
                                    </p>

                                    <p className="p-recruit-data-box--title">
                                    パフォーマンスクッキー
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        このクッキーにより訪問者数と通信トラフィックソースのカウントが可能となります。当社はこのクッキーによ
                                        り、最も人気があるページ、人気が低いページの確認や、訪問者がサイト内をどのように移動するかを理解し、
                                        サイトのパフォーマンスの測定や改善に役立てています。これらのクッキーで収集されるデータは統計化される
                                        ため、匿名性が保たれます。このクッキーの利用が停止された場合、当社は利用者がサイトを訪問したことや利
                                        用者のサイト上での動きを把握することはできません。
                                        </p>

                                        <table className="c-table u-margin-top__1">
                                            <thead>
                                                <tr>
                                                    <th>
                                                    Cookieサブグループ
                                                    </th>
                                                    <th>
                                                    Cookie
                                                    </th>
                                                    <th>
                                                    使用されている<br />
                                                    Cookie
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    co.jp<br />
                                                    .jp
                                                    </td>            
                                                    <td>
                                                    <Link to="https://cookiepedia.co.uk/cookies/_ga" className="c-text--link">
                                                    _ga
                                                    </Link>
                                                    </td>
                                                    <td>
                                                    ファーストパーティー
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                    acrossjapan.co.jp<br />
                                                    mosjapan.jp<br />
                                                    moslite.jp<br />
                                                    mox.jp
                                                    </td>            
                                                    <td>
                                                    <Link to="https://cookiepedia.co.uk/cookies/_gid" className="c-text--link">
                                                    _gid,&nbsp;_gat
                                                    </Link>
                                                    </td>
                                                    <td>
                                                    ファーストパーティー
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                </div>

                                <div className="p-recruit-data-box">

                                    <p className="p-recruit-data-box--title">
                                    ターゲティングクッキー
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        このクッキーは、当社の広告パートナーにより設定される場合があります。広告パートナーは利用者の興味関心
                                        に基づくプロファイルを作成し、当社以外のサイトでも関連性のある広告を表示するため等にこのクッキーを使
                                        用します。このクッキーは直接個人情報を保持することはありませんが、利用者のブラウザや端末を一意に識別
                                        します。このクッキーの利用が停止された場合、ターゲット性のないより一般的な広告が表示されるようになり
                                        ます。
                                        </p>

                                        <table className="c-table u-margin-top__1">
                                            <thead>
                                                <tr>
                                                    <th>
                                                    Cookieサブグループ
                                                    </th>
                                                    <th>
                                                    Cookie
                                                    </th>
                                                    <th>
                                                    使用されている<br />
                                                    Cookie
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    google.co.jp
                                                    </td>            
                                                    <td>
                                                    <Link to="https://cookiepedia.co.uk/cookies/_gcl_au" className="c-text--link">
                                                    _gcl_au
                                                    </Link>,&nbsp;
                                                    <Link to="https://cookiepedia.co.uk/cookies/IDE" className="c-text--link">
                                                    IDE
                                                    </Link>,&nbsp;
                                                    <Link to="https://cookiepedia.co.uk/cookies/IDE" className="c-text--link">
                                                    AID
                                                    </Link>
                                                    </td>
                                                    <td>
                                                    サードパーティー
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    yahoo.co.jp
                                                    </td>            
                                                    <td>
                                                    A,&nbsp;B,&nbsp;XA,&nbsp;XB
                                                    </td>
                                                    <td>
                                                    サードパーティー
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">

                                    <p className="p-recruit-data-box--title">
                                    必須クッキー
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        このクッキーはウェブサイトが正常に機能するために必要なもので、当社のシステム上で利用を停止することが
                                        できません。これらは通常、プライバシー設定、ログイン、フォームへの入力等、サービスのリクエストに相当
                                        する利用者のアクションに応じてのみ設定されます。ブラウザの設定でこのクッキーをブロックすることは可能
                                        ですが、サイトの一部が機能しなくなる可能性があります。このクッキーが個人を特定できる情報を保持するこ
                                        とはありません。
                                        </p>

                                        <table className="c-table u-margin-top__1">
                                            <thead>
                                                <tr>
                                                    <th>
                                                    Cookieサブグループ
                                                    </th>
                                                    <th>
                                                    Cookie
                                                    </th>
                                                    <th>
                                                    使用されている<br />
                                                    Cookie
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    acrossjapan.co.jp
                                                    </td>            
                                                    <td>
                                                    gdpr-as
                                                    </td>
                                                    <td>
                                                    ファーストパーティー
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">

                                    <p className="p-recruit-data-box--title is-sub u-margin-bottom__1">
                                    外部送信に関する公表事項
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社では、以下の利用者情報を外部に送信しています（以下「外部送信」といいます。）。利用者情報の外部送信に関し、電気通信事業法の外部送信規律に関する規定（電気通信事業法第27条の12）に基づき、以下の事項を公表いたします。
                                        </p>


                                        <p className="p-recruit-data-box--title">
                                        Google Analytics
                                        </p>

                                        <div className="p-recruit-data-box">
                                            <table className="p-recruit-data-box-table c-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="is-title">
                                                        送信先サービス／運営会社
                                                        </td>
                                                        <td className="is-data">
                                                        Google Analytics/ Google LLC
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        当社における利用目的
                                                        </td>
                                                        <td className="is-data">
                                                        閲覧の傾向や履歴の分析のため
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        送信先における利用目的
                                                        </td>
                                                        <td className="is-data">
                                                        当サイトが提供するサービスの利用状況を把握する
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        送信する情報の内容
                                                        </td>
                                                        <td className="is-data">
                                                        閲覧した人や機器を識別する情報（Googleアカウント、ブラウザ識別子、デバイス識別子など）
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        送信先プライバシーポリシー
                                                        </td>
                                                        <td className="is-data">
                                                        <ExtLink link="https://policies.google.com/privacy" title="送信先プライバシーポリシー" classTag="c-text--link">https://policies.google.com/privacy<i className="fa fa-external-link ml" target="true"></i></ExtLink>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        オプトアウト
                                                        </td>
                                                        <td className="is-data">
                                                        <ExtLink link="https://tools.google.com/dlpage/gaoptout/" title="オプトアウト" classTag="c-text--link">https://tools.google.com/dlpage/gaoptout/<i className="fa fa-external-link ml"></i></ExtLink>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <p className="p-recruit-data-box--title">
                                        Yahoo広告
                                        </p>

                                        <div className="p-recruit-data-box">
                                            <table className="p-recruit-data-box-table c-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="is-title">
                                                        送信先サービス／運営会社
                                                        </td>
                                                        <td className="is-data">
                                                        Yahoo広告/ LINEヤフー株式会社
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        当社における利用目的
                                                        </td>
                                                        <td className="is-data">
                                                        広告の掲載・配信・広告効果の分析のため
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        送信先における利用目的
                                                        </td>
                                                        <td className="is-data">
                                                        広告、各種情報の配信
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        送信する情報の内容
                                                        </td>
                                                        <td className="is-data">
                                                        Cookie、当社ウェブサイトの閲覧・行動履歴、利用者の属性情報
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        送信先プライバシーポリシー
                                                        </td>
                                                        <td className="is-data">
                                                        <ExtLink link="https://www.lycorp.co.jp/ja/company/privacypolicy/" title="送信先プライバシーポリシー" classTag="c-text--link">https://www.lycorp.co.jp/ja/company/privacypolicy/<i className="fa fa-external-link ml"></i></ExtLink>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="is-title">
                                                        オプトアウト
                                                        </td>
                                                        <td className="is-data">
                                                        <ExtLink link="https://btoptout.yahoo.co.jp/optout/index.html" title="オプトアウト" classTag="c-text--link">https://btoptout.yahoo.co.jp/optout/index.html<i className="fa fa-external-link ml"></i></ExtLink>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>



                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-data-box">

                                <p className="p-recruit-data-box--title">
                                Google Analyticsの利用については、以下のURLもご参照ください。
                                </p>

                                <div className="p-recruit-data-box-cnt">
                                    <p className="p-recruit-data-box-cnt--text">
                                        <Link to="https://policies.google.com/technologies/partner-sites" className="c-text--link" target="_blank">
                                        https://policies.google.com/technologies/partner-sites<i className="fa fa-external-link ml"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>

                        </div>



                        <div className="p-recruit-entry is-single">
                            <Link to="/privacyform" title="メールフォームでのお問い合わせ" className="p-recruit-entry--btn">
                            メールフォームでのお問い合わせ
                            </Link>
                            <p className="p-recruit-data-box-cnt--text">
                            (質問及び苦情の申出先)
                            </p>
                        </div>

                        <div className="p-recruit-data-box-sign">
                            <p className="p-recruit-data-box-cnt--text">
                            2022年4月1日<br />
                            株式会社アクロスソリューションズ<br />
                            石川県金沢市北安江3丁目6-6 メッセヤスダ1F/2F<br />
                            代表取締役社長　能登 満
                            </p>
                        </div>
                    </section>

                </article>

            </Contents>

        </>
    )
}

export default Policy;


